window.gaia = {
    onInteraction: () => {
        return new Promise(function(resolve, reject) {
            const events = ['touchstart', 'mouseover', 'scroll', 'keydown'];

            const complete = () => {
                events.forEach((event) => {
                    window.removeEventListener(event, complete);
                });

                resolve();
            }

            events.forEach((event) => {
                window.addEventListener(event, complete, { once: true });
            })
        });
    },

    loadScript: (url) => {
        return new Promise(function(resolve, reject) {
            let script = document.createElement('script');

            script.onload = function() { resolve(script); };
            script.onerror = function() { reject(new Error('Failed to load ' + url)); };

            script.src = url;
            script.type = 'module';
            document.head.appendChild(script);
        });
    },

    formatPrice: (price, currency) => {
        return price.toLocaleString("en-GB", { style: "currency", currency: "GBP" });
    },

    getCSRF: () => {
        return document.querySelector(`meta[name='csrf-token']`).getAttribute('content');
    },

    postFetch: async function (url, params = {}) {

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'X-Requested-With': 'XMLHttpRequest',
                },
                body: JSON.stringify({ ...params, _token: gaia.getCSRF() }),
            });

            if (!response.ok) {
                // If the response status is not in the successful range, throw an error
                throw response;
            }

            return response;
        } catch (error) {
            throw error; // Rethrow the error to let the caller handle it
        }
    },

    fetch: async function (url, method, params = {}) {
        const methods = ['GET', 'POST', 'DELETE', 'PATCH'];

        if (! methods.includes(method) || ! method) {
            console.error(`Incorrect method ${method}`);
            return;
        }

        try {
            const response = await fetch(url, {
                method: method,
                headers: {
                    "Content-Type": "application/json",
                    'X-Requested-With': 'XMLHttpRequest',
                },
                // body: JSON.stringify({ ...params, _token: gaia.getCSRF() }),
            });

            if (!response.ok) {
                // If the response status is not in the successful range, throw an error
                throw response;
            }

            return response.json();
        } catch (error) {
            throw error; // Rethrow the error to let the caller handle it
        }
    },

    patchFetch: async function (url, params = {}) {
        try {
            const response = await fetch(url, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    'X-Requested-With': 'XMLHttpRequest',
                },
                body: JSON.stringify({ ...params, _token: gaia.getCSRF() }),
            });

            if (!response.ok) {
                // If the response status is not in the successful range, throw an error
                throw response;
            }

            return response;
        } catch (error) {
            throw error; // Rethrow the error to let the caller handle it
        }
    },

    deleteFetch: async function (url, params = {}) {
        try {
            const response = await fetch(url, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    'X-Requested-With': 'XMLHttpRequest',
                },
                body: JSON.stringify({ ...params, _token: gaia.getCSRF() }),
            });

            if (!response.ok) {
                // If the response status is not in the successful range, throw an error
                throw response;
            }

            return response;
        } catch (error) {
            throw error; // Rethrow the error to let the caller handle it
        }
    },


    fetchJson: async function (url, method, params = {}) {
        const methods = ['GET', 'POST', 'DELETE', 'PATCH'];

        if (! methods.includes(method) || ! method) {
            console.error(`Incorrect method ${method}`);
            return;
        }

        let body = {
            body: JSON.stringify({ ...params, _token: gaia.getCSRF() })
        }

        if (method == 'GET') {
            body = null;
        }

        try {
            const response = await fetch(url, {
                method: method,
                headers: {
                    "Content-Type": "application/json",
                    'X-Requested-With': 'XMLHttpRequest',
                },
                ...body
            });

            if (!response.ok) {
                // If the response status is not in the successful range, throw an error
                throw response.json();
            }

            return response.json();
        } catch (error) {
            throw await error.json();
        }
    },

    startElapsed: () => {
        return performance.now();
    },

    elapsed: (startTime, thresholdTime) => {
        return new Promise((resolve, reject) => {
            const currentTime = performance.now();
            const elapsedTime = currentTime - startTime;

            if (elapsedTime >= thresholdTime) {
                resolve();
            } else {
                const remainingTime = thresholdTime - elapsedTime;
                setTimeout(() => resolve(), remainingTime);
            }
        });
    },

    status: (attributes) => {
        Alpine.store('messages').addStatus({
            role: 'status',
            ...attributes
        });
    },

    alert: (attributes) => {
        Alpine.store('messages').addStatus({
            role: 'alert',
            ...attributes
        });
    },
}



doOnInteraction: () => {
    return new Promise(function(resolve, reject) {
        let script = document.createElement('script');

        script.onload = function() { resolve(script); };
        script.onerror = function() { reject(new Error('Failed to load ' + path_to_script)); };

        script.src = path_to_script;
        document.head.appendChild(script);
    });
},

/**
 * A nifty little utility script that lets you listen for changes on radio buttons.
 */
document.addEventListener('livewire:navigated', function () {
    let elements = document.querySelectorAll('[data-watch-checked]');
    let array = {};


    function loopInputs(name) {
        const selector = name ? `[data-watch-checked][name="${name}"]` : `[data-watch-checked]`;
        const elements = document.querySelectorAll(selector);

        elements.forEach(element => {
            if (!array.hasOwnProperty(element.getAttribute('name'))) {
                array[`${element.getAttribute('name')}`] = [];
                array[`${element.getAttribute('name')}`]['elements'] = [];
            }

            // Theres a weird bug in firefox where the attribute returns false in live server, but the attribute is true, so `|| element.attributes.checked is needed
            if (element.checked || element.attributes.checked) {
                array[`${element.getAttribute('name')}`]['checked'] = element;
            }

            if (!array[element.getAttribute('name')]['elements'].includes(element)) {
                array[element.getAttribute('name')]['elements'].push(element);
            }
        });

    }

    loopInputs();

    for (const index in array) {
        const value = array[index]['elements'];
        for (const element in value) {
            array[index]['elements'][element].addEventListener('change', (event) => {
                if (event.target.checked) {
                    if (array[index]['checked']) {
                        array[index]['checked'].dispatchEvent(new CustomEvent('unchecked', { bubbles: true }));
                    }
                    array[index]['checked'] = event.target;
                    event.target.dispatchEvent(new CustomEvent('checked'));
                    console.log(event.target);

                }
            })
        }
    }
});
